//Version 2.0 App.ts
//Maintains the intial state and reducer code
import * as constants from "../actions/constants";
import {
  ActionTypes,
  ApiConfig,
  AppGlobalStates,
  AppState,
} from "../types/app";

export const api: ApiConfig = {
  credentials: {
    client_id: process.env.REACT_APP_TOKN_API_CLIENT_ID || "",
    client_secret: process.env.REACT_APP_TOKN_API_CLIENT_SECRET || "",
    audience: process.env.REACT_APP_TOKN_API_AUDIENCE || "",
    grant_type: "client_credentials",
  },
  url: process.env.REACT_APP_TOKN_API_URL || "",
  authUrl: process.env.REACT_APP_TOKN_AUTH_URL || "",
  token: {
    access_token:
      "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjAtQkJKOVRwZUxad3R2ZmJEUkNiRiJ9.eyJ0aXMiOiJvbnRyYWNrIiwicHNwIjoib250cmFjayIsImlzcyI6Imh0dHBzOi8vdG9rbi51ay5hdXRoMC5jb20vIiwic3ViIjoiQ0x6Q1NtdjIwVTB1c2h6U2lGazk2WUc5dTFlVnA1NkZAY2xpZW50cyIsImF1ZCI6Imh0dHBzOi8vZGV2LmFwaS50b2stbi51ayIsImlhdCI6MTcyMzU0MjM4MiwiZXhwIjoxNzI2MTM0MzgyLCJndHkiOiJjbGllbnQtY3JlZGVudGlhbHMiLCJhenAiOiJDTHpDU212MjBVMHVzaHpTaUZrOTZZRzl1MWVWcDU2RiJ9.b3x-sjPzLpnL6LymmEkROb7iH7bHXHWY0-FFrAjFNuX0S5cKXvdA56cj6tEpb2aFb3xrIj_Fwdzmyhx6vgpqyibfTsXnCS1wQCqVPab1viUjWMbad6Sl8_rnbzbk43z3mcKzMSNbu-u9RQ87UWOgBgVCsY-ASBsg0lf_4i8mh3TTuI0TyWtjcvdC5jEDhBtmPAOYe4Myyyj2PHMwaFEKBYuknUk8Tmh107GMSQpQ1wgqj31AS4QMHkw6UGgdPeIjntd88C96PPZYT_6_d9zrdx6_FIKNdGH4URQInn5gVnmZWs-4ex8j_Lay2scDtaeguzGVKq_lhPmHM1M5gdG4Lw",
    token_type: "Bearer",
  },
};
console.log(process.env);
console.log(api);

const initialState: AppState = {
  ready: false,
  environment: process.env.REACT_ENVIRONMENT || "",
  currentState: AppGlobalStates.Default,
  modal: false,
};

const app = (state = initialState, action: ActionTypes): AppState => {
  switch (action.type) {
    case constants.INIT:
      return {
        ...state,
        ready: action.data as boolean,
      };

    case constants.RESET_HOME:
      return initialState;

    case constants.SET_CURRENT_STATE:
      return {
        ...state,
        currentState: action.data as AppGlobalStates,
      };
    default:
      return state;
  }
};

export default app;
